import * as React from 'react'
import { css } from 'linaria'
import { graphql } from 'gatsby'

import { Layout } from '../components/layout'
import { SEO } from '../components/seo'
import { Header } from '../components/typography'
import { PostQuery } from '../../graphql-types'
import './dracula.css'

const stylePost = css`
  margin-bottom: 3rem;

  blockquote {
    background: var(--color-quote);
    margin: 0;
    padding: 1rem;
    padding-left: 1.5rem;
    border-left: 2px solid var(--color-quote-variant);
  }

  ul {
    list-style-type: circle;
  }

  table {
    font-size: 0.9em;
    background: var(--color-table);

    thead tr th, tbody tr td {
      padding: 0.5rem;
      border-bottom: 1px solid var(--color-table-variant);
    }

    tbody tr:last-of-type td {
      border: none;
    }
  }

  hr {
    border: none;
    border-top: 1px solid var(--color-table-variant);
  }

  .footnotes {
    font-size: 0.9em;

    ol {
      padding-left: 1rem;
    }
  }
`

interface Props {
  data: PostQuery;
}

const Post: React.FC<Props> = ({ data }) => {
  if (
    !data.post ||
    !data.post.frontmatter ||
    !data.post.frontmatter.title ||
    !data.post.excerpt ||
    !data.post.html
  ) {
    throw new Error('Missing fields')
  }
  const { title, thumbnail } = data.post.frontmatter
  const { excerpt } = data.post
  let thumbnailSrc = ''
  if (thumbnail && thumbnail.publicURL) {
    thumbnailSrc = thumbnail.publicURL
  }
  return (
    <Layout>
      <SEO title={title} description={excerpt} thumbnail={thumbnailSrc} />
      <Header>{title}</Header>
      <div className={stylePost} dangerouslySetInnerHTML={{
        __html: data.post.html
      }} />
    </Layout>
  )
}

export default Post

export const query = graphql`
  query Post($slug: String!) {
    post: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        thumbnail {
          publicURL
        }
      }
      html
      excerpt(pruneLength: 200)
    }
  }
`